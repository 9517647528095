// Dependencies
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
// Hooks
import useMedia from "../hooks/useMedia"
// Components
import Image, { GIF } from "../components/Image"
import Slideshow from "../components/Slideshow"
// import { StaticImage } from "gatsby-plugin-image"

const Caption = ({ html, counter }) => (
	<div className="slide-caption">
		{counter && <span className={`slide-caption-counter`}>{`${counter}`}&ensp;</span>}
		<div className={`slide-caption-text`} dangerouslySetInnerHTML={{__html: html}} />
	</div>
)

const GallerySingle = ({ asset, maxHeight }) => {

	const isPortrait = asset.image?.height > 0.85
	const hasCaptionMargin = asset.description

	return (
		<div className={`slideshow is-single ${hasCaptionMargin ? 'has-caption' : ''}`} style={{ maxHeight: maxHeight || 'auto' }}>
			<div className="slide-wrapper">
				<div className={`slide guttersx4 ${isPortrait ? 'portrait' : 'landscape'}`}>
					<Image image={asset.image} file={asset.file} objectFit="contain" alt={asset.description || asset.title} />
				</div>
				<Caption html={asset.description || ''} />
			</div>
		</div>
	)
}

const GallerySlideshow = ({ media, maxHeight }) => {

	const hasCaptionMargin = media.length > 1 || media[0].description

	return media.length > 1 ? (
		<Slideshow hasCaptionMargin={hasCaptionMargin} maxHeight={maxHeight}>
			{media.map((asset, index) => {
				const counter = media.length > 1 ? `${index + 1} / ${media.length}` : ''
				const isPortrait = asset.image?.height > 0.85
				return (
					<div className="slide-wrapper" key={`${asset.id}${asset.index}`}>
						<div className={`slide guttersx4 ${isPortrait ? 'portrait' : 'landscape'}`}>
							<Image image={asset.image} file={asset.file} objectFit="contain" alt={asset.description || asset.title} />
							{/* <div className="slide-dimmer" /> */}
						</div>
						<Caption counter={counter} html={asset.description || ''} />
					</div>
				)
			})}
		</Slideshow>
	) : (
		<GallerySingle asset={media[0]} maxHeight={maxHeight} />
	)
}

const GalleryGrid = ({ media }) => {

	return (
		<div className="grid-gallery outerx4 innerx4 guttersx4">

			{media.map((asset) => (
				<div className="thumbnail" key={asset.id}>
					<Image image={asset.thumbnail} alt={asset.description || asset.title} />
					<div className="caption">
						{`${asset.description || ''}`}
					</div>
				</div>
			))}
			
		</div>
	)
}

const Gallery = (props) => {

	const isMobile = useMedia(
    // Media queries
    ['(min-width: 768px) and (orientation: landscape)'],
    // Column counts (relates to above media queries by array index)
    [false],
    // Default column count
    true
	);

	return props.galleryType === 'Slideshow' ? (
		<GallerySlideshow {...props} />
	) : (
		isMobile ? (
			<GallerySlideshow {...props} />
		) : (
			<GalleryGrid {...props} />
		)
	)
}

export default Gallery

export const ContentfulGalleryFragment = graphql`
	fragment ContentfulGalleryFragment on ContentfulGallery {
		__typename # isRequired
		contentful_id # isRequired
		# Content
		media {
			id: contentful_id
			title
			description
			file {
				contentType
				url
			}
			image: gatsbyImageData(
				layout: FULL_WIDTH
				placeholder: NONE
				quality: 80
			)
			thumbnail: gatsbyImageData(
				layout: CONSTRAINED
				placeholder: NONE
				width: 960
			)
		}
		galleryType
		maxHeight
	}
`
