// Dependencies
import React from 'react'
import { useTransition, animated } from "react-spring";

const TransitionMount = ({ className, show, children }) => {
  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: show,
    // delay: 200,
    // config: config.molasses,
    // onRest: () => set(!show),
  })
  return transitions(
    (styles, item) => (
			item && <animated.div className={className} style={styles}>{children}</animated.div>
		)
  )
}

export default TransitionMount