import { useEffect, useCallback } from 'react';

export default function useUpdateSizes(callback, deps = [], triggerOnce) {

	useEffect(() => {
		// Handler to call on window resize...
    function updateSizes() {
			// console.log('updateSizes()');
			// Should contain any other updates needed on resize...
			!callback || callback();
			// Also save a CSS variable for window height...
			document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    }

		function handleResize() {
			// This conditional is to optionally prevent the resizing, so you don't get that horrible jumping bollocks on safari
			const isTouch = window.matchMedia("(hover: none)").matches

			if (isTouch) {
				if (!triggerOnce) {
					updateSizes()
				}
			} else {
				updateSizes()
			}
		}
		// Add event listener...
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size...
		updateSizes();
		// Cleanup...
		return () => window.removeEventListener("resize", handleResize);
	}, [...deps]);

  return;
}
