// Dependencies
import React from "react"
import { Link } from "gatsby"
// Hooks & Helpers
//...
// Components
import Overlay from "../components/Overlay"
import { Search } from "../components/Icons"
import { useHeaderState, useHeaderActions } from '../components/State'

const Menu = () => {

	const { showMainMenu } = useHeaderState()
	const { toggleMainMenu, toggleSearch } = useHeaderActions()

	const handleClose = () => {
		toggleMainMenu(false)
	}

	return (
		<Overlay show={showMainMenu}>
			<div className="overlay__wrapper">
				<button className="close-icon" onClick={handleClose} />
				<nav className="padx4 h0">
					<Link to="/projects" className="link--yellow" onClick={handleClose}>Projects</Link>
					<Link to="/practice" className="link--blue" onClick={handleClose}>Practice</Link>
					<Link to="/people" className="link--red" onClick={handleClose}>People</Link>
					<Link to="/journal" className="link--green" onClick={handleClose}>Journal</Link>
					<button className="menu-search-btn" onClick={() => toggleSearch()}><Search /></button>
				</nav>
			</div>
		</Overlay>
	)
}

export default Menu
