// Dependencies
import React, { useState, useEffect, useRef } from "react";
import { useTransition, animated } from "react-spring";
// Hooks & Helpers
import useMouse from "../hooks/useMouse";
// Components
import { Arrow } from "../components/Icons";

// TODO: useGesture to allow swiping/drag to change slides

const Right = () => (
	<svg width="86" height="159" viewBox="0 0 86 159" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M80.8302 79.5027L0.962982 3.0838L3.03699 0.916199L85.1697 79.5028L3.03695 158.084L0.963021 155.916L80.8302 79.5027Z" fill="#2D2D2D"/>
	</svg>
)

const Left = () => (
	<svg width="86" height="159" viewBox="0 0 86 159" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M5.16978 79.4973L85.037 155.916L82.963 158.084L0.830276 79.4972L82.9631 0.916168L85.037 3.08383L5.16978 79.4973Z" fill="#2D2D2D"/>
	</svg>
)

const SlideshowCursor = () => {

	const [isLeft, setLeft] = useState(false)
	const { x, y } = useMouse()

	useEffect(() => {
		if (x < (window.innerWidth / 2)) {
			setLeft(true)
		} else {
			setLeft(false)
		}
	}, [x])

	const css = {
		opacity: x > 0 ? 1 : 0,
		position: 'fixed',
		top: y,
		left: x,
		zIndex: 'var(--z-cursor)',
		pointerEvents: 'none',
		transform: `translate(-50%, -50%)`
	}

	return (
		<div className="h3 slideshow__cursor" style={css}>
			{isLeft ? <Left /> : <Right />}
		</div>
	)
}

function useSlideshowCursor() {
	const [show, set] = useState(false)

	const handlers = {
		onMouseOver: () => set(true),
		onMouseLeave: () => set(false)
	}

	return [show, handlers]
}

const Slideshow = ({ 
	className = "slideshow",
	maxHeight,
	hasCaptionMargin,
	options = { 
		timer: null 
	}, 
	children 
}) => {

	// Content
	const slides = React.Children.toArray(children)

	// UX
	const [isActive, setActive] = useState(false)
	const [showCursor, cursorHandlers] = useSlideshowCursor()
	const [currentIndex, setIndex] = useState(0)

	const transitions = useTransition(currentIndex, {
    key: currentIndex,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, position: 'absolute', top: 0 },
    // config: { duration: 3000 },
  })


	// UI
	const prev = () => {
		setIndex(prevState => {
			return prevState > 0 ? prevState - 1 : slides.length - 1
		})
  }

  const next = () => {
		setIndex(prevState => {
			return prevState < slides.length - 1 ? prevState + 1 : 0
		})
  }

	const handleClick = (event) => {
		setActive(true)
		if (event.clientX < (window.innerWidth * 0.5)) {
			prev()
		} else if (event.clientX > (window.innerWidth * 0.5)) {
			next()
		}
	}

	// Autoplay
	useEffect(() => {
		let autoplay
		if (options.timer) {
			autoplay = setInterval(next, options.timer);
		}
		return () => clearInterval(autoplay)
	}, [])

	// Style
	const style = { cursor: slides.length > 1 ? 'none' : 'auto' }

	return (
		<div className={`${className} ${hasCaptionMargin ? 'has-caption' : ''} ${isActive ? 'active' : ''}`} onClick={handleClick} {...cursorHandlers} style={{ maxHeight: maxHeight || 'auto' }}>
			{transitions((style, i) => {
				return (
					<animated.div className="slideshow__slide" style={style}>
						{slides[i]}
					</animated.div>
				)
			})}

			{slides.length > 1 && (
				<>
					<div className="slideshow__prev">
						<div className="left-arrow">
							<Arrow /></div>
					</div>
					{!isActive && <div className="slideshow__prompt">View gallery</div>}
					<div className="slideshow__next">
						<div className="right-arrow"><Arrow /></div>
					</div>
				</>
			)}
			
			{/* {showCursor && slides.length > 1 && <SlideshowCursor />} */}
		</div>
	)
}

export default Slideshow