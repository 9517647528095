import { useEffect, useRef } from "react";
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default function useLockBodyScroll(willLockBodyScroll) {

	const ref = useRef()

	useEffect(() => {
		if (!ref.current) {
			console.log('no ref')
			return;
		}
		if (willLockBodyScroll) {
			disableBodyScroll(ref.current);
		}
		return () => {
			clearAllBodyScrollLocks();
			// enableBodyScroll(ref.current);
		};
	}, [willLockBodyScroll]);

	return [ref]
}