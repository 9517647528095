// Dependencies
import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import * as JsSearch from 'js-search'
// Hooks & Helpers
import useSessionStorage from '../hooks/useSessionStorage'
import useLockBodyScroll from '../hooks/useLockBodyScroll';
// Components
import Overlay from "../components/Overlay"
import LinkType from "../components/LinkType"
import { Arrow } from "../components/Icons"
import { useJournalLink } from "../components/JournalLink"
import { useHeaderState, useHeaderActions } from '../components/State'


// ---------------------------------------------
// <Results query={"useSearchInput().value"} />

// Get the content from contentful...
function useContent() {

	const {
    allContentfulProject,
    allContentfulJournal,
    allContentfulPerson
  } = useStaticQuery(SearchDataQuery)

	const sanitizedData = useRef(
		[
			...allContentfulProject.nodes,
			...allContentfulJournal.nodes,
			...allContentfulPerson.nodes
		].map(item => {
			return {
				...item,
				projectType: item.projectType?.map(e => e.title) || [],
				projectForm: item.projectForm?.map(e => e.title) || [],
				projectLocation: item.projectLocation?.map(e => e.title) || [],
			}
		})
	)

	return sanitizedData.current
}

// Setup the js-seach search index....
function useSearch(query) {
	// Content
	const data = useContent()
	const [searchIndex, setIndex] = useState([])
	const [results, setResults] = useState([])

	useEffect(() => {
		const dataToSearch = new JsSearch.Search('title')
		dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
		dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
		dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex()
		dataToSearch.addIndex('title')
		dataToSearch.addIndex(['content', 'raw'])
		dataToSearch.addIndex(['projectType'])
		dataToSearch.addIndex(['projectForm'])
		dataToSearch.addIndex(['projectLocation'])
		dataToSearch.addDocuments(data)
		setIndex(dataToSearch)
	}, [])

	useEffect(() => {
		if (query && searchIndex) {
			setResults(searchIndex.search(query))
		} else {
			setResults([])
		}
	}, [query, searchIndex])

	return [results]
}

// UI component to display the results from a query....

const getType = (entry) => {
	switch (entry.__typename) {
		case 'ContentfulProject':
			return {
				name: 'Project',
				path: '/projects/',
				className: 'color--yellow'
			}
			break;
		case 'ContentfulJournal':
			return {
				name: 'Journal',
				to: '/journal/',
				className: 'color--green'
			}
			break;
		case 'ContentfulPerson':
			return {
				name: 'Person',
				path: '/people/',
				className: 'color--red'
			}
			break;
		default:
			return false
	}
}

const JournalLink = (props) => {
	const [to, href, icon] = useJournalLink(props)

	return (
		<LinkType to={to} href={href} className="result-columns guttersx4">
			<div className="title">{props.title}</div>
			<div className="type">Journal</div>
			<div className="cta">{icon}</div>
		</LinkType>
	)
}

const Results = ({ query }) => {

	const [results] = useSearch(query)

	return (
		<div className="search-results h3">
			{results?.map((result, index) => {

				const type = getType(result)
				
				return (
					<div className={`search-results-item inner ${type ? type.className : ''}`} key={`${result.title}${index}`}>
						{type.name === 'Journal' ? (
							<JournalLink {...result} />
						) : (
							<Link to={`${type.path}${result.slug}`} className="result-columns guttersx4">
								<div className="title">{result.title}</div>
								<div className={`type`}>{type?.name}</div>
								<div className="cta"><Arrow className="icon" /></div>
							</Link>
						)}
					</div>
				)
			})}
		</div>
	)
}

// -----------------------------------
// <SearchInput value={"useSearchInput().value"} handleChange={"useSearchInput().handleChange"} />

function useSearchInput() {
	// const [value, set] = useSessionStorage('afk-search-query', "")
	const [value, set] = useState("")
	const handleChange = event => set(event.target.value)
	return [value, handleChange]
}

const SearchInput = ({ value, handleChange, focus }) => {
	return (
		<div className={`search-field ${value ? 'active' : ''}`}>
			<div className="search-input-width">{value}</div>
			<input type="text" value={value} autoFocus={focus} onChange={handleChange} placeholder="Search…" />
		</div>
	)
}

// ------------------------------------
// <Search />

const Search = () => {

	const { showSearch } = useHeaderState()
	const { toggleSearch } = useHeaderActions()
	const [value, handleChange] = useSearchInput()

	// Lock body...
	const [ref] = useLockBodyScroll(showSearch)

	return (
		<Overlay className="search-overlay" show={showSearch}>
			<div className="overlay__wrapper" ref={ref}>
				<button className="close-icon" onClick={() => toggleSearch()} />
				<div className="search-input-wrapper padx4 h0">
					<SearchInput focus={showSearch} value={value} handleChange={handleChange} />
				</div>
				<Results query={value} />
			</div>
		</Overlay>
	)
}

const SearchDataQuery = graphql`
  query SearchDataQuery {
		allContentfulProject(filter: {slug: {ne: "placeholder-project-do-not-edit-or-delete"}}) {
			nodes {
				__typename
				title
				slug
				projectType: type {
					title
				}
				projectForm: form {
					title
				}
				projectLocation: location {
					title
				}
				content {
					raw
				}
			}
		}
		allContentfulJournal(filter: {slug: {ne: "template-do-not-delete"}}) {
			nodes {
				...JournalLinkFragment
			}
		}
		allContentfulPerson(filter: {slug: {ne: "template"}}) {
			nodes {
				__typename
				title: name
				slug
			}
		}
  }
`

export default Search
