// Dependencies
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"

export const BackgroundImage = (props) => (
	<GatsbyImage {...props} className={`img ${props.className || ''}`} style={{
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 'var(--z-background)'
	}} />
)

export const GIF = ({ src, alt }) => {

	return (
		<div className="img">
			<img src={src} alt={alt || ''} />
		</div>
	)
}

const Image = (props) => {

	const isGIF = props.file?.contentType === 'image/gif'

	return (
		isGIF ? (
			<GIF src={props.file?.url} alt={props.alt} />
		) : (
			<GatsbyImage {...props} className={`img ${props.className || ''}`} />
		)
	)
}

export default Image