// Dependencies
import React from "react"
import { Link } from "gatsby"
// Hooks
import useScroll from "../hooks/useScroll"
// Components
// import Logo from "../components/Logo"
import { useHeaderActions } from "../components/State"
import { useFilterState } from "../components/Filters"
import { Burger, Search, Arrow } from "../components/Icons"

const FilterButton = ({ onClick }) => {

	const { activeFilters } = useFilterState()

	return (
		<button className={`filter h3`} onClick={onClick}>
			<span className="bold">Filter</span>
			<span className="filter-count">{activeFilters.length}</span>
		</button>
	)
}

const Header = ({ headerTitle = "AFK", headerLink, dontHideLogo, showFilterButton, hideSplash }) => {

	const [isTopOrBottom] = useScroll(dontHideLogo)

	const { toggleFilterMenu, toggleMainMenu, toggleSearch } = useHeaderActions()

	// UI
	const handleMenuClick = () => {
		toggleMainMenu(o => !o)
	}

	const handleSearchClick = () => {
		toggleSearch(o => !o)
	}

	const handleFilterClick = () => {
		toggleFilterMenu(true)
	}

	const handleBackClick = (e) => {
		e.preventDefault()
		window.history.back()
	}

	return (
		<header className={!isTopOrBottom ? 'is-scrolling' : ''} style={{ opacity: hideSplash ? 1 : 0 }}>
			<Link to={headerLink || "/"} onClick={headerLink ? handleBackClick : null} className="title h3">
				{headerLink && <Arrow className="icon arrow" />}
				<strong>{headerTitle}</strong>
			</Link>
	
			<div className="actions">
				{showFilterButton && (
					<FilterButton onClick={handleFilterClick} />
				)}
				<div className="icons">
					<button className="search" onClick={handleSearchClick}><Search /></button>
					<button onClick={handleMenuClick}><Burger /></button>
				</div>
			</div>
		</header>
	)
}

export default Header
