// Dependencies
import React from "react"
// Components
import { LinkedIn, Instagram, Twitter } from "../components/Icons"

const SocialLink = ({ className = "inlb", href, title, linkedIn, instagram, twitter }) => (
	<a href={href} className={className} rel="noreferrer noopener" target="_blank" title={title}>
		{linkedIn && <LinkedIn />}
		{instagram && <Instagram />}
		{twitter && <Twitter />}
	</a>
)

export default SocialLink