import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// Components
import Image from "../components/Image"
import Gallery from '../components/Gallery'
import Quote from '../components/Quote'
import RichTextVideo from '../components/RichTextVideo'
import { Arrow } from '../components/Icons'

// MARKS
const Bold = ({ children }) => <strong>{children}</strong>
const Italic = ({ children }) => <i>{children}</i>

const Hyperlink = ({ data, children }) => {
	// console.log(data);
	return (
		<a href={data.uri} className="has-arrow" target="_blank" rel="noopener noreferrer">{children} <Arrow className="icon arrow" /></a>
	)
}

const DownloadLink = ({ data, children }) => {
	const file = data.target && data.target.file;
	return (
		file ? (
			<a href={file.url} className="has-arrow" download={file.title} target="_blank">{children} <Arrow className="icon arrow" /></a>
		) : (
			<span className="c-constraints">[Asset missing]</span>
		)
	)
}

const InlineEntry = ({ data: { target }, children }) => {
	if (!target) return null;

  if (target.__typename === 'ContentfulSiteCategory') {
		return (
			<Link to={`/${target.slug}`} className="has-arrow" style={{ color: target.colorHighlight }}>{children} <Arrow className="icon arrow" /></Link>
		)
  }

	if (target.__typename === 'ContentfulPerson') {
		return (
			<Link to={`/${target.slug}`} className="has-arrow link--person">{children} <Arrow className="icon arrow" /></Link>
		)
  }
  
	return null;
}

const EmbeddedAsset = ({ data }) => {
	if (!data.target) return null;
	return (
		<div className="rich-text-asset">
			<Image image={data.target.image} alt="Article Image" />
		</div>
	)
}

const EmbeddedEntry = ({ data }) => {
	const type = data?.target?.__typename;

	if (!type) return null;

	if (type === 'ContentfulGallery') {
		return <Gallery {...data.target} />
	}

	if (type === 'ContentfulQuote') {
		return <Quote {...data.target} />
	}

	if (type === 'ContentfulVideo') {
		return <RichTextVideo {...data.target} />
	}

  return null
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  },
  renderNode: {
    // [BLOCKS.PARAGRAPH]: (_node, children) => <P>{children}</P>,
    // [BLOCKS.HEADING_1]: (_node, children) => <H1>{children}</H1>,
    // [BLOCKS.HEADING_2]: (_node, children) => <H2>{children}</H2>,
    // [BLOCKS.HEADING_3]: (_node, children) => <H3>{children}</H3>,
    // [BLOCKS.HEADING_4]: (_node, children) => <H4>{children}</H4>,
    // [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    [BLOCKS.EMBEDDED_ASSET]: node => <EmbeddedAsset {...node} />,
    [BLOCKS.EMBEDDED_ENTRY]: node => <EmbeddedEntry {...node} />,
		[INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node}>{children}</Hyperlink>,
		[INLINES.ASSET_HYPERLINK]: (node, children) => <DownloadLink {...node}>{children}</DownloadLink>,
		[INLINES.ENTRY_HYPERLINK]: (node, children) => <InlineEntry {...node}>{children}</InlineEntry>
  },
  renderText: text => text.split('\n')
    .reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])
}

const RichText = ({ content }) => (
	content && (
		renderRichText(content, options)
	)
)

RichText.propTypes = {
  content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array
	})
}

export default RichText

// export const ContentfulRichTextFragment = graphql`
// 	fragment ContentfulRichTextFragment on ContentfulRichTextNode {
// 		raw
// 		references {
// 			... on ContentfulGallery {
// 				...ContentfulGalleryFragment
// 			}
// 			... on ContentfulAsset {
// 				__typename # isRequired
// 				contentful_id # isRequired
// 				title
// 				description
// 				file {
// 					url
// 				}
// 				image: gatsbyImageData(
// 					layout: CONSTRAINED
// 					height: 1800
// 				)
// 			}
// 			... on ContentfulQuote {
// 				...ContentfulQuoteFragment
// 			}
// 		}
// 	}
// `