import { useEffect, useCallback } from "react"
import { useInView } from 'react-intersection-observer'
// Components
// ...

export const updateColor = (color) => {
	document.documentElement.style.setProperty('--header-color', (color || `var(--black)`));
	if (color && color === 'var(--black)') {
		document.documentElement.style.setProperty('--logo-black-opacity', '1');
		document.documentElement.style.setProperty('--logo-white-opacity', '0');
	} else if (color && color === 'var(--white)') {
		document.documentElement.style.setProperty('--logo-black-opacity', '0');
		document.documentElement.style.setProperty('--logo-white-opacity', '1');
	} else {
		document.documentElement.style.setProperty('--logo-black-opacity', '1');
		document.documentElement.style.setProperty('--logo-white-opacity', '0');
	}
}

export function useResetHeader() {
	useEffect(() => {
		updateColor()
	}, [])
}

export default function useUpdateHeader(color) {
	const { ref, inView, entry } = useInView({
    threshold: 0.1,
  });

	useEffect(() => {
		
		const handleScroll = () => {
			const { top, bottom } = entry.target.getBoundingClientRect()
			const threshold = window.innerHeight * 0.25;
			// if ((top < threshold) || (bottom > threshold)) {
			// 	update()
			// }
			if (top < threshold) {
				updateColor(color)
			}
		}

		if (inView) {
			updateColor(color)
			window.addEventListener('scroll', handleScroll)
		}

		return () => {
			window.removeEventListener('scroll', handleScroll, false)
			// document.documentElement.style.setProperty('--header-color', `var(--black)`);
			// if (inView) {
			// 	update()
			// }
		}
	}, [inView, entry])

	return [ref, inView]
}