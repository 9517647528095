// Dependencies
import React from "react"
import { graphql } from "gatsby"
// Components
import LinkType from "../components/LinkType"
import Image from "../components/Image"
import { Instagram, Twitter, LinkedIn, Arrow } from "../components/Icons"
import { useFilters } from "../components/Filters"

const Info = ({ className = "", summary, icon, date, linkLabel }) => {
	return (
		<div className={className}>
			<p>{summary?.copy}</p>
			{linkLabel ? (
				<h5 className="color--highlight inner">
					{icon && <span>{icon}</span>}
					<span>{linkLabel} <Arrow className="icon arrow" /></span>
				</h5>
			) : (
				<h5 className="color--highlight inner">
					<span>Read more <Arrow className="icon arrow" /></span>
				</h5>
			)}
		</div>
	)
}

export function useJournalLink(entry) {
	const to = entry.content ? `/journal/${entry.slug}` : null
	const href = entry.linkTweet || entry.linkInstagram || entry.linkLinkedIn || entry.linkUrl
	const icon = entry.linkTweet ? (
		<Twitter className="icon" />
	) : entry.linkInstagram ? (
		<Instagram className="icon" />
	) : entry.linkLinkedIn ? (
		<LinkedIn className="icon" />
	) : null;
	return [to, href, icon]
}

const JournalLink = (props) => {

	// Content
	const { 
		date,
		summary, 
		thumbnail, 
		linkLabel, 
		type,
		focus 
	} = props
	
	const [to, href, icon] = useJournalLink(props)
	const tags = [...([type] || []), ...(focus || [])]
	const isFiltered = useFilters(tags)

	return (
		isFiltered && (
			thumbnail ? (
				<LinkType to={to} href={href} className="journal-link link--green block p3 has-arrow">
					<Image image={thumbnail.gatsbyImageData} alt={'Test'} />
					<Info className="outer" summary={summary} icon={icon} date={date} linkLabel={linkLabel} />
				</LinkType>
			) : (
				<LinkType to={to} href={href} className="journal-link link--green block p2--summary has-arrow">
					<Info summary={summary} icon={icon} date={date} linkLabel={linkLabel} />
				</LinkType>
			)
		)
	)
}

export default JournalLink

export const JournalLinkFragment = graphql`
	fragment JournalLinkFragment on ContentfulJournal {
		__typename
		id
		title
		slug
		date(formatString: "DD MMM YYYY")
		summary {
			copy: summary
		}
		thumbnail {
			file {
				contentType
				url
			}
			gatsbyImageData(
				layout: FULL_WIDTH
			)
		}
		linkTweet
		linkInstagram
		linkLinkedIn
		linkUrl
		linkLabel
		content {
			raw
		}
		type {
			slug
		}
		focus {
			slug
		}
	}
`