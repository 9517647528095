// Dependencies
import React, { useRef } from 'react'
import TransitionMount from '../components/TransitionMount';
import useLockBodyScroll from '../hooks/useLockBodyScroll';

const Overlay = ({ className = "", show, children }) => {

	// const [ref] = useLockBodyScroll(show)

	return (
		<TransitionMount className={`overlay ${className}`} show={show}>{children}</TransitionMount>
	)
}

export default Overlay