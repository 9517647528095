import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          defaultMetaTitle
          defaultMetaDescription {
						text: defaultMetaDescription
					}
					defaultMetaKeywords
          defaultShareImage {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 640
						)
          }
					## Journal
					journalIntroduction {
						text: journalIntroduction
					}
					## Footer
					talkWithUs
					footerEmail
					footerTelephone
					footerAddress {
						raw
					}
					footerLinks {
						raw
						references {
							#... on ContentfulAsset {
							#	__typename
							#	contentful_id
							#	file {
							#		url
							#	}
							#}
							... on ContentfulSiteCategory {
								__typename
								contentful_id
								slug
								colorHighlight
							}
						}
					}
					linkedIn
					instagram
					twitter
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
