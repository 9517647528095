import React from 'react'

export const Instagram = ({ className = "icon" }) => (
	<svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M16 2.884C20.272 2.884 20.7787 2.9 22.4667 2.97733C26.8027 3.17467 28.828 5.232 29.0253 9.536C29.1027 11.2227 29.1173 11.7293 29.1173 16.0013C29.1173 20.2747 29.1013 20.78 29.0253 22.4667C28.8267 26.7667 26.8067 28.828 22.4667 29.0253C20.7787 29.1027 20.2747 29.1187 16 29.1187C11.728 29.1187 11.2213 29.1027 9.53467 29.0253C5.188 28.8267 3.17333 26.76 2.976 22.4653C2.89867 20.7787 2.88267 20.2733 2.88267 16C2.88267 11.728 2.9 11.2227 2.976 9.53467C3.17467 5.232 5.19467 3.17333 9.53467 2.976C11.2227 2.9 11.728 2.884 16 2.884ZM7.784 16C7.784 11.4627 11.4627 7.784 16 7.784C20.5373 7.784 24.216 11.4627 24.216 16C24.216 20.5387 20.5373 24.2173 16 24.2173C11.4627 24.2173 7.784 20.5373 7.784 16ZM22.62 7.46C22.62 6.4 23.48 5.54 24.5413 5.54C25.6013 5.54 26.46 6.4 26.46 7.46C26.46 8.52 25.6013 9.38 24.5413 9.38C23.48 9.38 22.62 8.52 22.62 7.46Z" fill="black"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M16 2.884C20.272 2.884 20.7787 2.9 22.4667 2.97733C26.8027 3.17467 28.828 5.232 29.0253 9.536C29.1027 11.2227 29.1173 11.7293 29.1173 16.0013C29.1173 20.2747 29.1013 20.78 29.0253 22.4667C28.8267 26.7667 26.8067 28.828 22.4667 29.0253C20.7787 29.1027 20.2747 29.1187 16 29.1187C11.728 29.1187 11.2213 29.1027 9.53467 29.0253C5.188 28.8267 3.17333 26.76 2.976 22.4653C2.89867 20.7787 2.88267 20.2733 2.88267 16C2.88267 11.728 2.9 11.2227 2.976 9.53467C3.17467 5.232 5.19467 3.17333 9.53467 2.976C11.2227 2.9 11.728 2.884 16 2.884ZM16 0C11.6547 0 11.1107 0.0186667 9.404 0.096C3.59333 0.362667 0.364 3.58667 0.0973333 9.40267C0.0186667 11.1107 0 11.6547 0 16C0 20.3453 0.0186667 20.8907 0.096 22.5973C0.362667 28.408 3.58667 31.6373 9.40267 31.904C11.1107 31.9813 11.6547 32 16 32C20.3453 32 20.8907 31.9813 22.5973 31.904C28.4027 31.6373 31.64 28.4133 31.9027 22.5973C31.9813 20.8907 32 20.3453 32 16C32 11.6547 31.9813 11.1107 31.904 9.404C31.6427 3.59867 28.4147 0.364 22.5987 0.0973333C20.8907 0.0186667 20.3453 0 16 0Z" fill="black"/>
		<path d="M16 21.3333C13.0547 21.3333 10.6667 18.9467 10.6667 16C10.6667 13.0547 13.0547 10.6667 16 10.6667C18.9453 10.6667 21.3333 13.0547 21.3333 16C21.3333 18.9467 18.9453 21.3333 16 21.3333Z" fill="black"/>
	</svg>
)

export const Twitter = ({ className = "icon" }) => (
	<svg className={className} width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M36.9155 3.55158C35.5573 4.15453 34.0976 4.5606 32.5656 4.74364C34.1299 3.80691 35.3312 2.3226 35.8957 0.553733C34.4329 1.42125 32.8117 2.05189 31.0859 2.39182C29.7062 0.919811 27.7358 0 25.5578 0C20.6681 0 17.075 4.56214 18.1793 9.29809C11.8868 8.98277 6.3064 5.96801 2.57024 1.38587C0.586034 4.78979 1.54122 9.24272 4.91284 11.4976C3.67309 11.4576 2.5041 11.1177 1.48431 10.5501C1.40125 14.0587 3.91612 17.3411 7.55845 18.0717C6.49251 18.3608 5.32506 18.4285 4.13761 18.2009C5.10049 21.2095 7.89684 23.3983 11.2131 23.4598C8.02912 25.9562 4.01764 27.0714 0 26.5976C3.35162 28.7464 7.33388 30 11.6099 30C25.6717 30 33.6162 18.124 33.1363 7.47231C34.616 6.4033 35.9003 5.06973 36.9155 3.55158V3.55158Z" fill="black"/>
	</svg>
)

export const LinkedIn = ({ className = "icon" }) => (
	<svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 0V32H32V0H0ZM10.6667 25.3333H6.66667V10.6667H10.6667V25.3333ZM8.66667 8.976C7.37867 8.976 6.33333 7.92267 6.33333 6.624C6.33333 5.32533 7.37867 4.272 8.66667 4.272C9.95467 4.272 11 5.32533 11 6.624C11 7.92267 9.956 8.976 8.66667 8.976ZM26.6667 25.3333H22.6667V17.8613C22.6667 13.3707 17.3333 13.7107 17.3333 17.8613V25.3333H13.3333V10.6667H17.3333V13.02C19.196 9.572 26.6667 9.31733 26.6667 16.3213V25.3333Z" fill="black"/>
	</svg>
)

export const Burger = ({ className = "" }) => (
	<svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M0 0H27V3H0V0ZM0 11H27V14H0V11ZM27 22H0V25H27V22Z" fill="#2D2D2D" />
	</svg>
)

export const Search = ({ className = "" }) => (
	<svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM19.4263 21.4267C17.3834 23.0383 14.804 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 14.7421 23.0803 17.2694 21.5324 19.2902L29.1211 26.8789L26.9998 29.0002L19.4263 21.4267Z" fill="#2D2D2D"/>
	</svg>
)

export const Arrow = ({ className = "icon arrow" }) => (
	<svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M0 17.2694H27.0869L14.3707 30.1263L16.1735 31.9458L32 15.9729L16.1735 0L14.3707 1.8195L27.0869 14.6763L0 14.6763L0 17.2694Z" fill="#2D2D2D"/>
	</svg>
)

export default {}