// Dependencies
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { getImage } from "gatsby-plugin-image"
// Hooks & Helpers
import useSettings from "../hooks/useSettings";
// Components
// ...

function Meta({ description, lang, meta, title, shareImage, theme }) {

  const { defaultMetaTitle, defaultMetaDescription, defaultMetaKeywords, defaultShareImage } = useSettings();

	const shareImageSrc = getImage(shareImage)?.images?.fallback?.src
	const defaultImageSrc = getImage(defaultShareImage)?.images?.fallback?.src

  const metaTitle = title || defaultMetaTitle;
  const metaDescription = description || defaultMetaDescription?.text;
  const metaImage = `https:${shareImageSrc || defaultImageSrc || ""}`;
  const metaAuthor = `@afk_studios`;
	const metaKeywords = defaultMetaKeywords || "Architecture";

	// CSS
	const style = [{
		"cssText": theme ? `
			:root {
				--background: ${theme.background};
				--highlight: ${theme.highlight};
			}
		` : ``
	}];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
			style={style}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
				{
					name: `keywords`,
					content: metaKeywords
				},
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          property: 'twitter:image',
          content: metaImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Meta.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  shareImage: PropTypes.object
};

export default Meta;
