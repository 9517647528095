// Dependencies
import React from "react"
import { Link } from "gatsby"
// Hooks
import useScroll from "../hooks/useScroll"
// Components
import { Arrow } from '../components/Icons'

const ContactBar = () => {

	const [isTopOrBottom] = useScroll()

	return (
		<div className={`contact-bar ${!isTopOrBottom ? 'is-scrolling' : ''}`}>
			<Link to="/" className="title"><strong>Arney Fender Katasalidis</strong></Link>
			<Link to="/contact" className="contact-link has-arrow">Talk with us <Arrow className="icon arrow" /></Link>
		</div>
	)
}

export default ContactBar
