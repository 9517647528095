// Dependencies
import { useEffect, useState } from "react"

export default function useScroll(dontHide) {
	const [show, set] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.scrollY || window.pageYOffset || document.body.scrollTop
			if ((window.innerHeight + scrollTop) >= document.body.offsetHeight) {
        set(true)
    	} else if (scrollTop > 0) {
				set(false)
			}	else {
				set(true)
			}
		}
		
		if (!dontHide) {
			window.addEventListener('scroll', handleScroll)
		}
		
		return () => {
			if (!dontHide) {
				window.removeEventListener('scroll', handleScroll, false)
			}
		}
	}, [])

	return [show]
}

// function useScalingScroll() {
// 	const ref = useRef()
// 	const [scale, set] = useState(1)

// 	useEffect(() => {
// 		const handleScroll = () => {
// 			const scrollTop = window.scrollY
// 			const logoHeight = ref.current.offsetHeight
// 			const diff = logoHeight - scrollTop
// 			// if (diff >= 0) {
// 			// 	set(diff / logoHeight)
// 			// }
// 			set(diff / logoHeight)
// 		}
// 		window.addEventListener('scroll', handleScroll)
// 		return () => window.removeEventListener('scroll', handleScroll, false)
// 	}, [])

// 	// const css = {
// 	// 	transform: `scale(${scale})`,
// 	// 	transformOrigin: `20% 0%`
// 	// }

// 	const css = {
// 		opacity: scale
// 	}

// 	return [ref, css]
// }