// Dependencies
import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useLocation, navigate } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
// Hooks & Helpers
// ...
// Components
import { useHeaderState, useHeaderActions } from '../components/State'

// ------------------------------------------
// STATE

export const FilterActions = React.createContext()
export const FilterContext = React.createContext()

export function useFilterState() {
	const { updateFilter, resetFilter } = useContext(FilterActions)
	const { activeFilters } = useContext(FilterContext)
	return { activeFilters, updateFilter, resetFilter }
}

export function useFilters(tags) {
	const { activeFilters } = useFilterState()

	// If not active filters, return true... (eg. show everything)
	if (!activeFilters || activeFilters.length < 1) {
		return true;
	}

	const isFiltered = tags && tags.length > 0 && activeFilters.every(f => tags.find(t => t?.slug === f?.value))
	return isFiltered
}

// https://dmitripavlutin.com/react-state-management/
function useUnique(initial) {
	
	// Initial state object
	const [items, setItems] = useState(initial);
	
	// UI
	const update = (newItem, isMultiple) => {
		// Copy as a Set...
		const uniqueItems = new Set(items);

		// Check if already exists..
		const findItem = items.find(item => item.value === newItem.value)
		const hasItem = uniqueItems.has(findItem)
		
		// If not add...
		if (!hasItem) {
			if (!isMultiple) {
				uniqueItems.forEach((item) => {
					if (item.category === newItem.category) {
						uniqueItems.delete(item)
					}
				})
			}
			uniqueItems.add(newItem)
		} else {
			uniqueItems.forEach((item) => {
				if (item.value === newItem.value) {
					uniqueItems.delete(item)
				}
			})
		}

		// Update the state array/object
    setItems([...uniqueItems]);
	}
	
	// Update all filters (from URL)
	const updateAll = (array) => {
		setItems(array)
	}
	
	// Return hook
  return [items, update, updateAll];
};

// https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752
const groupBy = key => array =>
	array.reduce((objectsByKeyValue, obj) => {
		const value = obj[key];
		objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
		return objectsByKeyValue;
	}, {});

function useEncodeSearchUri(filters, location) {
	
	// Double-check how often this fires...
	const updateLocation = useCallback(() => {
		const sorter = groupBy('category')
		const groupByCategory = sorter(filters)
		const categories = Object.keys(groupByCategory)
		const searchParams = categories.map((item, index) => {
			const relatedFilters = groupByCategory[item].map(filter => filter.value).join()
			return `${index > 0 ? '&' : '?'}${item}=${relatedFilters}`
		})
		const uri = searchParams.join("")

		if (uri && filters.length > 0) {
			navigate(uri)
		} else if (location.search) {
			navigate(location.pathname)
		}
	}, [filters])
	useEffect(updateLocation, [filters])

	return;
}

// https://attacomsian.com/blog/javascript-convert-query-string-to-object
const parseParams = (querystring) => {
	// parse query string
	const params = new URLSearchParams(querystring);
	// new object
	const obj = {};
	// iterate over all keys
	for (const key of params.keys()) {
		if (params.getAll(key).length > 1) {
			obj[key] = params.getAll(key);
		} else {
			obj[key] = params.get(key);
		}
	}
	return obj;
};

function useDecodeSearchUri(callback) {
	const location = useLocation()
	const params = parseParams(location.search)
 
	// 1. turn value of each param into array
	const data = Object.entries(params).map((entry, index) => {
		if (!entry[1]) {
			return
		}
		return entry[1].split(',').map((tag) => {
			return { category: entry[0], value: tag }
		})
	})
	// 2. convert into same data structure as filters for activeFilters
	return data.flat();
}

export const FilterState = ({ children }) => {

	const location = useLocation()

	// UX (State)
	const [activeFilters, update, updateAll] = useUnique([])

	useEncodeSearchUri(activeFilters, location)

	const urlFilters = useDecodeSearchUri()

	useEffect(() => {
		if (urlFilters && urlFilters.length > 0) {
			updateAll(urlFilters)
		}
	}, [])

	// UI (Actions)
	const updateFilter = (type, value, multiple) => {
		update({ category: type, value: value }, multiple)
	}

	const resetFilter = () => {
		updateAll([])
		// navigate(location.pathname)
	}

	return (
		<FilterActions.Provider value={{ updateFilter, resetFilter }}>
			<FilterContext.Provider value={{ activeFilters }}>
				{children}
			</FilterContext.Provider>
		</FilterActions.Provider>
	)
}

// ------------------------------------------
// UI

const FilterButton = ({ title, slug, type, multiple }) => {

	const { updateFilter, activeFilters } = useFilterState()
	const isActive = activeFilters.find(item => item.value === slug)

	const handleClick = () => {
		updateFilter(type, slug, multiple)
	}
	
	return (
		<button className={isActive ? 'color--highlight' : ''} onClick={handleClick}>{title}</button>
	)
}

const FilterList = ({ label, type, categories, multiple }) => {

	return (
		<ul className="filter-list outerx2">
			<li className="label">{label}</li>
			{categories.map((category, index) => (
				<li key={category.id}>
					<FilterButton {...category} multiple={multiple} type={type} />
				</li>
			))}
		</ul>
	)
}

const ProjectFilters = (props) => {

	const { projectTypes, projectForms, projectLocations } = useContent()

	return (
		<div className="filter-menu-contents">
			<FilterList label="Type" type="type" categories={projectTypes.nodes} />
			<FilterList label="Form" type="form" categories={projectForms.nodes} />
			<FilterList label="Location" type="location" categories={projectLocations.nodes} />
		</div>
	)
}

const JournalFilters = (props) => {

	const { journalTypes, journalFocus } = useContent()

	return (
		<div className="filter-menu-contents">
			<FilterList label="Type" type="type" categories={journalTypes.nodes} />
			<FilterList label="Focus" type="focus" categories={journalFocus.nodes} />
		</div>
	)
}

const PeopleFilters = (props) => {

	const { personRole } = useContent()

	return (
		<div className="filter-menu-contents">
			<FilterList label="Role" type="role" categories={personRole.nodes} />
		</div>
	)
}

const FilterMenuHeader = ({ closeFilterMenu }) => {
	const { activeFilters, resetFilter } = useFilterState()

	const reset = () => {
		resetFilter()
	}

	const handleClick = () => {
		closeFilterMenu()
		reset()
	}

	const closeMenuClick = () => {
		closeFilterMenu()
	}

	return (
		<div className="filter-menu-header">
			<button className="hide-mobile" onClick={closeMenuClick}>
				<span>Filter</span>
				<span className="filter-count">{activeFilters.length}</span>
			</button>
			<button className="show-mobile" onClick={handleClick}>(Clear)</button>

			<button className="h3 hide-mobile" onClick={() => reset()}>Clear filters</button>
			<button className="h3 show-mobile" onClick={closeMenuClick}>Apply filters</button>
		</div>
	)
}



// ------------------------------------------
// MAIN COMPONENT

const Filters = ({ type, children }) => {
	
	// UX
	const { showFilterMenu } = useHeaderState()
	const { toggleFilterMenu } = useHeaderActions()

	// UI
	const closeFilterMenu = () => {
		toggleFilterMenu(false)
	}
	
	return (
		<div className={`filter-menu p3 ${showFilterMenu ? 'is-open' : ''}`} onMouseLeave={closeFilterMenu} style={{
			transform: showFilterMenu ? `translateX(0%)` : `translateX(100%)`
		}}>
			<FilterMenuHeader closeFilterMenu={closeFilterMenu} />

			{type === 'projects' && <ProjectFilters />}
			{type === 'journal' && <JournalFilters />}
			{type === 'people' && <PeopleFilters />}
		</div>
	)
}

// ------------------------------------------
// DATA/CONTENT

function useContent() {
  const data = useStaticQuery(graphql`
    {
			## Project categories
      projectTypes: allContentfulProjectType(sort: {fields: title, order: ASC}) {
				nodes {
					id
					title
					slug
				}
			}
			projectForms: allContentfulProjectForm(sort: {fields: title, order: ASC}) {
				nodes {
					id
					title
					slug
				}
			}
			projectLocations: allContentfulProjectLocation(sort: {fields: title, order: ASC}) {
				nodes {
					id
					title
					slug
				}
			}
			## Journal categories
			journalTypes: allContentfulJournalType(sort: {fields: title, order: ASC}) {
				nodes {
					id
					title
					slug
				}
			}
			journalFocus: allContentfulJournalFocus(sort: {fields: title, order: ASC}) {
				nodes {
					id
					title
					slug
				}
			}
			## People
			personRole: allContentfulPersonRole(sort: {fields: title, order: ASC}) {
				nodes {
					id
					title
					slug
				}
			}
    }
  `)
  return data
}

export default Filters

