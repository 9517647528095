// Dependencies
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
// Components
import ContactBar from "../components/ContactBar"
import Header from "../components/Header"
import Logo from "../components/Logo"
import Menu from "../components/Menu"
import Meta from "../components/Meta"
import Footer from "../components/Footer"
import Search from "../components/Search"
import State from "../components/State"
// Hooks
import useSessionStorage from "../hooks/useSessionStorage"
import useUpdateSizes from "../hooks/useUpdateSizes"
// CSS
import "../css/styles.scss"

function useSplashPage() {
	const [hide, setHide] = useSessionStorage('afk-splash', false)
	// const [hide, setHide] = useState(initial) // false
	const [render, setRender] = useState(!hide) // true

	const handleTransitionEnd = (event) => {
		event.stopPropagation()
		if (hide) {
			setRender(false)
		}
	}

	useEffect(() => {
		let timer
		const finish = () => setHide(true) // true
		timer = setTimeout(finish, 3000)
		window.addEventListener('click', finish)
		return () => {
			window.removeEventListener('click', finish, false)
			clearTimeout(timer)
		}
	}, [])

	return [hide, render, handleTransitionEnd]
}

const SplashBackground = ({ hideSplash, handleTransitionEnd }) => {

	const css = {
		opacity: !hideSplash ? 1 : 0
	}

	return (
		<div className="splash-background" onTransitionEnd={handleTransitionEnd} style={css} />
	)
}

const Layout = ({ 
	title, 
	headerTitle, 
	headerLink,
	dontHideLogo, 
	children, 
	theme, 
	showFilterButton,
	description,
	shareImage,
	hasSplash,
	hideContactBar,
	triggerResizeOnce,
	notFound
}) => {

	useUpdateSizes(null, [], triggerResizeOnce)

	const [hideSplash, renderSplash, handleTransitionEnd] = useSplashPage()

	return (
		<main>
			<Meta title={title} description={description} shareImage={shareImage} theme={theme} />
			<State hasSplash={hasSplash}>
				{renderSplash && hasSplash && <SplashBackground hideSplash={hideSplash} handleTransitionEnd={handleTransitionEnd} />}
				<Logo dontHideLogo={dontHideLogo} hideSplash={!hasSplash || hideSplash} />
				<Header headerTitle={headerTitle} hideSplash={!hasSplash || hideSplash} headerLink={headerLink} dontHideLogo={dontHideLogo} showFilterButton={showFilterButton} />
				<Menu />
				<Search />
				{children}
				{!hideContactBar && <ContactBar />}
				<Footer notFound={notFound} />
			</State>
		</main>
	)
}

export default Layout
