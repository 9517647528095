// Dependencies
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
// Hooks
// Components

const Quote = ({ className = "h1", copy, citation, plain }) => {

	return (
		<div className={`pullquote ${className} ${plain ? 'hide-marks' : ''}`}>
			<blockquote>
				<span>{copy.copy}</span>
				<cite>{citation}</cite>
			</blockquote>
		</div>
	)
}

export default Quote

export const ContentfulQuoteFragment = graphql`
	fragment ContentfulQuoteFragment on ContentfulQuote {
		__typename # isRequired
		contentful_id # isRequired
		# Content
		copy {
			copy
		}
		citation
	}
`
