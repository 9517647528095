// Dependencies
import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
// Hooks & helpers
import useVimeo from "../hooks/useVimeo"
import useMouse from "../hooks/useMouse";

function useCursor() {
	const [show, set] = useState(false)

	const handlers = {
		onMouseOver: () => set(true),
		onMouseLeave: () => set(false)
	}

	return [show, handlers]
}

const Cursor = ({ isPlaying }) => {

	const { x, y } = useMouse()

	const css = {
		display: x > 0 ? 'block' : 'none',
		position: 'fixed',
		top: y,
		left: x,
		zIndex: 'var(--z-cursor)',
		pointerEvents: 'none',
		transform: `translate(-50%, -50%)`
	}

	return (
		<div className="h3 video-cursor" style={css}>{isPlaying ? 'Pause' : 'Play'}</div>
	)
}

const Vimeo = ({ url, maxHeight }) => {

	const [showCursor, cursorHandlers] = useCursor()

	const { ref, isPlaying, playPause } = useVimeo({
		url: url,
		background: false,
		autoplay: false,
		loop: false,
		controls: false
	})

	const handleClick = () => {
		// console.log('do it')
		playPause()
	}

	return (
		<div className="vimeo mauto" style={{ maxHeight: maxHeight || 'auto' }}>
			<div className="iframe" ref={ref} />
			<div className="pabs fs" onClick={handleClick} {...cursorHandlers} style={{
				cursor: 'none'
			}} />
			{!isPlaying && (
				<div className="vimeo-mobile-button btn-white">Play Video</div>
			)}
			{showCursor && <Cursor isPlaying={isPlaying} />}
		</div>
	)
}

const RichTextVideo = ({ vimeoUrl, mp4, caption, maxHeight }) => {
	
	return (
		<div className="rich-text-asset">
			{vimeoUrl ? (
				<Vimeo url={vimeoUrl} maxHeight={maxHeight} />
			) : mp4 ? (
				<video src={mp4?.file?.url} playsInline muted autoPlay />
			) : null}

			{caption && (
				<div className="caption">
					{caption.text}
				</div>
			)}
		</div>
	)
}

export default RichTextVideo

export const ContentfulVideoFragment = graphql`
	fragment ContentfulVideoFragment on ContentfulVideo {
		__typename # isRequired
		contentful_id # isRequired
		vimeoUrl
		maxHeight
		mp4 {
			file {
				url
			}
		}
		# This conflicted with the other references called description in the RichTextNode, so have called it caption
		caption: description {
			text: description
		}
	}
`