// Dependencies
import React from "react";
import { Link } from "gatsby";

const LinkType = ({ className, to, href, children }) => {

	return (
		href ? (
			<a href={href} target="_blank" rel="noreferrer noopener" className={className}>{children}</a>
		) : to ? (
			<Link to={to} className={className}>
				{children}
			</Link>
		) : (
			<div className={className}>{children}</div>
		)
	)
}

export default LinkType