// Dependencies
import React from "react"
import { Link } from "gatsby"
// Hooks
import useScroll from "../hooks/useScroll"
// Components
// import { useHeaderState } from '../components/State'

import logoSvgBlack from '../images/logo.svg'
import logoSvgWhite from '../images/logo-white.svg'

const Logo = ({ fill = "#2D2D2D", dontHideLogo, hideSplash }) => {

	// UX
	// const [ref, css] = useScalingScroll()
	const [isTopOrBottom] = useScroll(dontHideLogo)

	// const { showLogo } = useHeaderState()

	// console.log(showLogo)

	// CSS
	const style = {
		opacity: isTopOrBottom ? 1 : 0
	}

	return (
		<Link to="/" className={`logo ${!hideSplash ? 'intro' : ''}`} style={style}>
			<img src={logoSvgBlack} className="logo__black" alt="AFK logo black" />
			<img src={logoSvgWhite} className="logo__white" alt="AFK logo white" />
			{/* <svg width="467" height="252" viewBox="0 0 467 252" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M467 251.987L393.727 153.179L465.826 68.4999H386.592L329.099 144.681H328.741V0H295.515V46.1406C293.189 45.6704 290.281 45.4464 286.792 45.4464C279.344 45.4464 273.931 47.0251 270.565 50.1601C267.188 53.3063 265.499 58.8374 265.499 66.7645V68.5111H295.526V117.093H265.488V251.999H328.741V164.274V161.699H329.087L387.755 251.999H467V251.987ZM161.385 153.168C161.385 138.893 159.707 126.868 156.386 117.071V117.06H199.855V220.235V251.966C178.92 251.966 161.933 235.183 161.519 214.324H161.496C161.429 209.923 161.385 205.467 161.385 200.933V153.168ZM51.2624 203.247C51.2624 196.484 54.3154 191.367 60.4103 187.874C66.5164 184.381 77.5208 181.761 93.4234 180.014L103.556 178.704V183.608C103.556 197.57 100.715 207.278 95.0562 212.73C89.3863 218.183 82.1954 220.915 73.4836 220.915C66.7289 220.915 61.3385 219.392 57.3125 216.336C53.2754 213.279 51.2624 208.923 51.2624 203.247ZM26.7616 159.398C19.7944 162.455 13.8002 166.105 8.79008 170.359C5.2897 173.326 2.38204 176.641 0 180.257V136.177H56.8224C57.6947 129.851 60.0879 124.879 64.0132 121.285C67.9386 117.691 73.3848 115.889 80.352 115.889C87.7554 115.889 93.3694 117.848 97.1829 121.778C100.996 125.708 103.009 132.683 103.233 142.715V144.675C93.649 145.111 84.3892 145.929 75.4538 147.127C66.5183 148.325 58.0302 149.915 49.967 151.874C41.4677 153.833 33.7288 156.341 26.7616 159.398Z" />
			</svg> */}
		</Link>
	)
}

const LayeredLogo = () => {

	return (
		<svg width="414" height="224" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 414 224" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill="#2D2D2D" d="M414 223.388L349.043 135.794L412.959 60.7256H342.718L291.75 128.26H291.432V0H261.978V40.9039C259.915 40.487 257.338 40.2885 254.245 40.2885C247.642 40.2885 242.843 41.6881 239.859 44.4673C236.865 47.2564 235.368 52.1597 235.368 59.1871V60.7355H261.987V103.803H235.358V223.398H291.432V145.63V143.347H291.74L343.749 223.398H414V223.388ZM143.069 135.794C143.069 123.139 141.582 112.479 138.638 103.794V103.784H177.174V195.249V223.379C158.615 223.379 143.555 208.5 143.188 190.009H143.168C143.109 186.108 143.069 182.157 143.069 178.137V135.794ZM45.4451 180.172C45.4451 174.177 48.1516 169.641 53.5548 166.544C58.9679 163.447 68.7233 161.125 82.8212 159.576L91.8033 158.415V162.762C91.8033 175.14 89.2851 183.746 84.2686 188.579C79.2422 193.413 72.8674 195.835 65.1444 195.835C59.1563 195.835 54.3777 194.485 50.8086 191.775C47.2296 189.066 45.4451 185.205 45.4451 180.172ZM23.7244 141.313C17.5479 144.022 12.234 147.258 7.79246 151.03C4.68936 153.66 2.1117 156.598 0 159.804V120.727H50.3734C51.1467 115.119 53.2683 110.712 56.7482 107.526C60.228 104.339 65.0562 102.741 71.2326 102.741C77.7957 102.741 82.7726 104.478 86.1533 107.962C89.534 111.446 91.3185 117.63 91.5168 126.523V128.26C83.0205 128.647 74.8116 129.372 66.8903 130.434C58.9689 131.496 51.4441 132.906 44.2961 134.643C36.7614 136.38 29.9009 138.603 23.7244 141.313Z" />
			<path fill="white" d="M414 223.388L349.043 135.794L412.959 60.7256H342.718L291.75 128.26H291.432V0H261.978V40.9039C259.915 40.487 257.338 40.2885 254.245 40.2885C247.642 40.2885 242.843 41.6881 239.859 44.4673C236.865 47.2564 235.368 52.1597 235.368 59.1871V60.7355H261.987V103.803H235.358V223.398H291.432V145.63V143.347H291.74L343.749 223.398H414V223.388ZM143.069 135.794C143.069 123.139 141.582 112.479 138.638 103.794V103.784H177.174V195.249V223.379C158.615 223.379 143.555 208.5 143.188 190.009H143.168C143.109 186.108 143.069 182.157 143.069 178.137V135.794ZM45.4451 180.172C45.4451 174.177 48.1516 169.641 53.5548 166.544C58.9679 163.447 68.7233 161.125 82.8212 159.576L91.8033 158.415V162.762C91.8033 175.14 89.2851 183.746 84.2686 188.579C79.2422 193.413 72.8674 195.835 65.1444 195.835C59.1563 195.835 54.3777 194.485 50.8086 191.775C47.2296 189.066 45.4451 185.205 45.4451 180.172ZM23.7244 141.313C17.5479 144.022 12.234 147.258 7.79246 151.03C4.68936 153.66 2.1117 156.598 0 159.804V120.727H50.3734C51.1467 115.119 53.2683 110.712 56.7482 107.526C60.228 104.339 65.0562 102.741 71.2326 102.741C77.7957 102.741 82.7726 104.478 86.1533 107.962C89.534 111.446 91.3185 117.63 91.5168 126.523V128.26C83.0205 128.647 74.8116 129.372 66.8903 130.434C58.9689 131.496 51.4441 132.906 44.2961 134.643C36.7614 136.38 29.9009 138.603 23.7244 141.313Z" />
		</svg>
	)
}

export default Logo