// Dependencies
import React, { useContext, useState, useEffect } from 'react'
// Components
import { FilterState } from "../components/Filters"

const HeaderContext = React.createContext()
const HeaderActions = React.createContext()

export function useHeaderActions() {
	const { toggleMainMenu, toggleFilterMenu, toggleSearch } = useContext(HeaderActions)
	return { toggleMainMenu, toggleFilterMenu, toggleSearch }
}

export function useHeaderState() {
	const { showMainMenu, showFilterMenu, showSearch } = useContext(HeaderContext)
	return { showMainMenu, showFilterMenu, showSearch }
}

const State = ({ children }) => {
	
	const [showFilterMenu, toggleFilterMenu] = useState(false)
	const [showMainMenu, setMainMenu] = useState(false)
	const [showSearch, setSearch] = useState(false)

	const toggleSearch = () => {
		setSearch(o => !o)
		setMainMenu(false)
	}

	const toggleMainMenu = () => {
		setMainMenu(o => !o)
		setSearch(false)
	}

	return (
		<HeaderActions.Provider value={{ toggleMainMenu, toggleFilterMenu, toggleSearch }}>
			<HeaderContext.Provider value={{ showMainMenu, showFilterMenu, showSearch }}>
				<FilterState>
					{children}
				</FilterState>
			</HeaderContext.Provider>
		</HeaderActions.Provider>
	)
}

export default State