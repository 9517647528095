// Dependencies
import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
// Hooks & Helpers
import useSettings from "../hooks/useSettings"
import useUpdateHeader from "../hooks/useUpdateHeader"
// Components
// import Logo from "../components/Logo"
import SocialLink from "../components/SocialLink"
import RichText from "../components/RichText"

const Footer = ({ notFound }) => {

	// Content
	const { talkWithUs, footerEmail, footerTelephone, footerAddress, footerLinks, instagram, twitter, linkedIn } = useSettings()

	// UX
	const [ref] = useUpdateHeader('var(--black)')

	return (
		<footer ref={ref}>
			<div className="center h0--footer">
				{!notFound ? (
					<div>
						<h1>{talkWithUs}</h1>
						<h1>
							<a href={`mailto:${footerEmail}`} target="_blank" rel="noopener noreferrer">{footerEmail}</a>
						</h1>
						<h1>
							<a href={`tel:${footerTelephone}`} target="_blank" rel="noopener noreferrer">{footerTelephone}</a>
						</h1>
					</div>
				) : (
					<div>
						<h1>Sorry, page not found...</h1>
					</div>
				)}
			</div>

			<div className="footer-contents p3">
				<section className="footer-address">
					<RichText content={footerAddress} />
				</section>
				<section className="footer-socials">
					<p className="mbx2">Follow us:</p>
					<div className="h1">
						{linkedIn && <SocialLink href={linkedIn} title={`AFK on LinkedIn`} linkedIn />}
						{instagram && <SocialLink href={instagram} title={`AFK on Instagram`} instagram />}
						{twitter && <SocialLink href={twitter} title={`AFK on Twitter`} twitter />}
					</div>
				</section>
				<section className="footer-links">
					<RichText content={footerLinks} />
				</section>
			</div>
		</footer>
	)
}

export default Footer
