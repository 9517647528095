// Dependencies
import { useRef, useState, useEffect } from "react"
import Player from '@vimeo/player'; // https://github.com/vimeo/player.js/

const defaultConfig = {
	url: 'https://vimeo.com/321249877',
  byline: false,
  color: "#FFF",
  portrait: false,
  title: false,
  background: true
}

export default function useVimeo(config = {}) {

  // UX
  const ref = useRef();
  const [player, setPlayer] = useState(null);
  const [ready, setReady] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(0.56);

  useEffect(() => {
    setPlayer(
      new Player(ref.current, {
				...defaultConfig,
				...config
			})
    )
  }, [])

  useEffect(() => {
    let isInitialized;
    if (player) {
      isInitialized = true

			const onPlay = () => setIsPlaying(true)
			const onPause = () => setIsPlaying(false)

			player.on('playing', onPlay)
			player.on('pause', onPause)

      // Promise.all([player.getVideoWidth(), player.getVideoHeight()])
      //   .then((dimensions) => {
      //     if (isInitialized && player) {
      //       setAspectRatio(dimensions[1] / dimensions[0]);
      //       setReady(true)
			// 			console.log(player)
      //     }
      //   });
    }
    return () => isInitialized = false
  }, [player])

  // UI
	const play = () => {
		player.play().then(function() {
			// the video was played
		}).catch(function(error) {
				switch (error.name) {
						case 'PasswordError':
								// the video is password-protected and the viewer needs to enter the
								// password first
								break;
		
						case 'PrivacyError':
								// the video is private
								break;
		
						default:
								// some other error occurred
								break;
				}
		});
	}

	const pause = () => {
		player.pause().then(function() {
			// the video was paused
		}).catch(function(error) {
				switch (error.name) {
						case 'PasswordError':
								// the video is password-protected and the viewer needs to enter the
								// password first
								break;
		
						case 'PrivacyError':
								// the video is private
								break;
		
						default:
								// some other error occurred
								break;
				}
		});
	}

	const playPause = () => {
		if (isPlaying) {
			pause()
		} else {
			play()
		}
	}

  return { ref, ready, isPlaying, playPause, aspectRatio }
}
